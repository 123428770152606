import { Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guard/auth.guard';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadComponent: () =>
      import('./modules/home/home.component').then((mod) => mod.HomeComponent),
    data: { state: 'home', animation: 'home' },
  },

  {
    path: 'filtre/:tag',
    loadComponent: () =>
      import('./modules/home/home.component').then((mod) => mod.HomeComponent),
    data: { state: 'home', animation: 'home' },
  },

  {
    path: 'restaurant/:idNav',
    loadComponent: () =>
      import('./modules/fiche-restaurant/fiche-restaurant.component').then(
        (mod) => mod.FicheRestaurantComponent
      ),
    data: { state: 'fiche', animation: 'fiche' },
  },

  {
    path: 'restaurant/:idNav/menu',
    loadComponent: () =>
      import('./modules/menu/menu.component').then((mod) => mod.MenuComponent),
  },

  {
    path: 'restaurant/:idNav/reservation/:idBook',
    loadComponent: () =>
      import('./modules/book/book.component').then((mod) => mod.BookComponent),
    canActivate: [AuthGuard],
  },

  {
    path: 'reservation/success/:session_id',
    loadComponent: () =>
      import('./modules/user/book-success/book-success.component').then(
        (mod) => mod.BookSuccessComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'experiences',
    loadComponent: () =>
      import('./modules/filters/exp/exp.component').then(
        (mod) => mod.ExpComponent
      ),
    data: { state: 'home', animation: 'home' },
  },

  {
    path: 'experience/:id',
    loadComponent: () =>
      import('./modules/experience/experience.component').then(
        (mod) => mod.ExperienceComponent
      ),
    data: { state: 'fiche', animation: 'fiche' },
  },

  {
    path: 'map',
    loadComponent: () =>
      import('./modules/map/map.component').then((mod) => mod.MapComponent),
  },

  {
    path: 'groupe',
    loadComponent: () =>
      import('./modules/groupe/groupe.component').then(
        (mod) => mod.GroupeComponent
      ),
  },

  
  {
    path: 'aide',
    loadComponent: () =>
      import('./modules/install-app/install-app.component').then(
        (mod) => mod.InstallAppComponent
      ),
  },

  // Auth

  {
    path: 'login',
    loadComponent: () =>
      import('./modules/auth/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },

  {
    path: 'register',
    loadComponent: () =>
      import('./modules/auth/register/register.component').then(
        (mod) => mod.RegisterComponent
      ),
  },

  {
    path: 'reset',
    loadComponent: () =>
      import('./modules/auth/reset-request/reset-request.component').then(
        (mod) => mod.ResetRequestComponent
      ),
  },

  {
    path: '__/auth/action',
    loadComponent: () =>
      import(
        './modules/auth/reset-pwd-confirmation/reset-pwd-confirmation.component'
      ).then((mod) => mod.ResetPwdConfirmationComponent),
  },

  // User

  {
    path: 'mon-compte',
    loadComponent: () =>
      import('./modules/user/profile/profile.component').then(
        (mod) => mod.ProfileComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'mes-recommandations',
    loadComponent: () =>
      import('./modules/user/recommandations/recommandations.component').then(
        (mod) => mod.RecommandationsComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'mes-favoris',
    loadComponent: () =>
      import('./modules/user/favoris/favoris.component').then(
        (mod) => mod.FavorisComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'mes-reservations',
    loadComponent: () =>
      import('./modules/user/book-history/book-history.component').then(
        (mod) => mod.BookHistoryComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'assistance',
    loadComponent: () =>
      import('./modules/support/support.component').then(
        (mod) => mod.SupportComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'restaurant/:idNav/rating/:idBook',
    loadComponent: () =>
      import('./modules/rating/rating.component').then(
        (mod) => mod.RatingComponent
      ),
  },

  { path: 'robots.txt', redirectTo: '/robots.txt' },

  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
