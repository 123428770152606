import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import {
  MatDialog,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { LanguageService } from '@services/language.service';
import { translate } from './Pipe/transalte.pipe';

@Component({
  selector: 'app-root',
  imports: [RouterModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  private swUpdate = inject(SwUpdate);
  private dialog = inject(MatDialog);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof navigator !== 'undefined') {
        const isInWebAppiOS =
          'standalone' in window.navigator && window.navigator['standalone'];

        let anotherPWA = false;

        if (window.matchMedia('(display-mode : standalone)').matches) {
          anotherPWA = true;
        }

        if (isInWebAppiOS === true || anotherPWA === true) {
          if (!this.swUpdate.isEnabled) {
            return;
          }
          this.swUpdate.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
              case 'VERSION_DETECTED':
                break;
              case 'VERSION_READY':
                this.applyUpdate();
                break;
              case 'VERSION_INSTALLATION_FAILED':
                break;
            }
          });
        }
      }
    }
  }

  applyUpdate(): void {
    this.dialog.open(DialogUpdatePWA, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      height: '45%',
      width: '90%',
      disableClose: true,
    });
  }
}

@Component({
  selector: 'DialogUpdatePWA',
  templateUrl: 'DialogUpdatePWA.html',
  styles: [
    `
      .updateBtn {
        display: flex;
        background-color: #d30050;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        font-family: 'Playfair Display, sans-serif';
        height: 40px;
        min-width: 100px;
        border: unset;
        margin-top: 20px;
        padding: 20px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  imports: [MatButtonModule, MatDialogContent, translate],
})
export class DialogUpdatePWA {
  dialogRef = inject<MatDialogRef<DialogUpdatePWA>>(MatDialogRef);

  languageService = inject(LanguageService);
  private swUpdate = inject(SwUpdate);

  reload(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch((error) => console.error('Failed to apply updates:', error));
    this.dialogRef.close();
  }
}
