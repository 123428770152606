<mat-dialog-content style="border-radius: 28px; text-align: center">
  <h3 style="color: gray; font-family: 'Playfair Display'">
    {{ "Une mise à jour est disponible pour bénéficier des dernières
    fonctionnalités. Merci." | translate : languageService.language() : "Your
    app has been updated ! Please confirm the upgrade to enjoy the latest
    features" }}
  </h3>

  <img
    style="text-align: center; height: 42%"
    src="../../assets/img/LogoClinOeilContour.webp"
    loading="eager"
    alt="Logo sympa"
  />

  <div style="display: flex; justify-content: center; align-items: center">
    <button class="updateBtn" (click)="reload()">
      {{ "Mettre à jour" | translate : languageService.language() : "Confirm"}}
    </button>
  </div>
</mat-dialog-content>
